angular.module('fingerink')
    .config(function config($stateProvider) {
        $stateProvider.state('main.document', {
            url: '/document/:id',
            views: {
                "main": {
                    controller: 'documentCtrl',
                    templateUrl: 'web/main/documents/document.tpl.html',
                    controllerAs: 'controller'
                }
            },
            data: {
                pageTitle: 'Documents'
            }
        });
    })

    .controller('documentCtrl', function ($rootScope, $scope, session, signatureService, changeSignatureSignerModal, $stateParams, $uibModal, userService, viewSignatureModal, viewSignerIdCardModal, swalService) {
        var that = this;
        that.dominio = $rootScope.dominio;
        that.viewIdCard = id => viewSignerIdCardModal.openModal(id);
        that.ver = () => viewSignatureModal.openModal(that.sign);
        that.iniciales = (name, surname) => (name + (surname ? ' ' + surname : '')).split(' ').map(a => a[0]).reduce((a, b) => a + b);

        that.estadoColor = { PENDING: '#0275d8', REFUSED: '#d80202', WAITING: '#d88e02', COMPLETED: '#5cb85c' };
        that.process = {
            Advanced: ['SEND', 'OPENSIGN', 'ACCEPT', 'VIEW', 'SIGN'],
            Fast: ['SEND', 'OPENSIGN', 'ACCEPT', 'VIEW', 'SIGN'],
            SelfSigning: ['SIGN'],
            Email: ['SEND', 'OPENSIGN', 'ACCEPT', 'VIEW'],
            InPerson: ['SIGN']
        };
        that.actionCompleted = action => that.signers.filter(signer => that.evidenciasFirmantesMap[signer.id].filter(evidence => evidence.action == action).length > 0).length;

        signatureService.getURL($stateParams.id, 0).then(response => that.urlImage = response.data);

        var init = ()=>
        signatureService.retrieveFirma($stateParams.id).then(response => {
            that.sign = response.data;
            that.sign.signers = that.sign.signers || [];

            userService.getUsuarios({ id: that.sign.user.id }).then((response) => that.sign.user = response.data[0]);

            if (that.sign.type != 'SelfSigning') {
                that.signers = that.sign.signers.filter(signer => signer.signType != 'DOCUMENT' && signer.completed !='SIGNERDELETED');
            } else {
                that.signers = [that.sign.documentFields];
            }

            that.evidenciasFirmantesMap = [];
            that.sign.evidences.filter(e => e.signatureSigner && e.signatureSigner.id).forEach(evidence => {
                that.evidenciasFirmantesMap[evidence.signatureSigner.id] = that.evidenciasFirmantesMap[evidence.signatureSigner.id] || [];
                that.evidenciasFirmantesMap[evidence.signatureSigner.id].push(evidence);
            });
        });
        init();


        let downloadLink = (link, name) => angular.element('<a></a>').attr('href', link).attr('download', name)[0].click();
        that.signed = () => signatureService.getSignedURL(that.sign.id).then(response => downloadLink(response.data, 'signed.pdf'));
        that.downloadEvidencias = () => signatureService.getTokenDownloadURL(that.sign.id).then(response => downloadLink('https://api.' + that.dominio + '/signatures/onlyEvidences/' + response.data, 'evidences.pdf'));
        that.downloadSigned = () => signatureService.getTokenDownloadURL(that.sign.id).then(response => downloadLink('https://api.' + that.dominio + '/signatures/onlySigned/' + response.data, 'evidences.pdf'));


        that.resend = (idFirmante) => swalService.requestSwal('¿Reenviar correo?', 'Se va a reenviar el correo al firmante', 'info', () => signatureService.resendFirma(that.sign.id, idFirmante))
            .then(
                () => swalService.basicSwal("¡Correcto!", "El correo se ha enviado satisfactoriamente", "success"),
                () => swalService.basicSwal('Algo ha ido mal', "Ha habido un error enviando el correo", "error")
            );



        that.cahngeSigner = (signer) => changeSignatureSignerModal.openModal(that.sign, signer).result.then(init);

    });
